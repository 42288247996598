<template>
  <div class="account-pending-wrapper">
    <div class="account-pending-header">
      <div class="account-pending-title">Pendências ({{ pendingItems?.length }})</div>
      <div class="card-actions">
        <CustomButton
          @click="showModalCreateGuide"
        >
          <TissIcon />
          Criar nova guia
        </CustomButton>
        <CustomButton
          :disabled="isButtonDisabledAccountsReceivable"
          @click="createBill"
          v-can="'FiConRec6'"
        >
          <FinancialIcon />
          Nova conta a receber
        </CustomButton>
      </div>
    </div>

    <AccountPendingTable
      :pending="pendingItems"
      :loading="loading"
      :checkedItems="checkedItems"
      @checked="changeCheckedItems"
      @discardPendingItem="discardPendingItem"
    />

    <div class="pagination-position">
      <b-pagination
        :value="page"
        :total-rows="count"
        :per-page="limit"
        first-number
        last-number
        size="sm"
        @input="onChangePage"
      />
    </div>

    <NewTissGuideModalVue
      :patient="patient"
      :healthPlan="healthPlan"
      :items="checkedItems"
      :disabledHealthPlan="canEditHealthPlan"
      @createGuide="createGuide"
      @setClinicHealthPlanToPending="setClinicHealthPlan"
    />

    <NewSusGuideModalVue
      :patient="patient"
      :healthPlan="healthPlan"
      :items="checkedItems"
      :disabledHealthPlan="canEditHealthPlan"
      @createGuideSus="createGuideSus"
      @setClinicHealthPlanToPending="setClinicHealthPlan"
      :closeModal="closeSusGuideModal"
    />

  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    TissIcon: () => import('@/assets/icons/tiss-icon.svg'),
    FinancialIcon: () => import('@/assets/icons/financial-icon.svg'),
    CustomButton: () => import('./CustomButton'),
    AccountPendingTable: () => import('./AccountPendingTable'),
    NewTissGuideModalVue: () => import('@/components/Tiss/Modal/NewTissGuideModal2'),
    NewSusGuideModalVue: () => import('@/components/Sus/Modal/NewSusGuideModal'),
  },
  props: {
    patient: Object,
    tissGuide: Object,
    clinicHealthPlan: Object,
    createGuide: Function,
    createGuideSus: Function
  },
  mounted() {
    this.getPendingAppointmentItems(this.patient.id)
    this.getTissGuideRequiredFields()
  },
  computed: {
    ...mapState(
      'pendingItems', ['pendingItems', 'checkedItems', 'page', 'count', 'limit', 'loading']
    ),
    disabledNewTissGuide() {
      return !this.checkedItems.length ||
        !this.checkedItems.some(item => item.invoicing_tiss_active) &&
        !this.checkedItems.some(item => item.invoicing_sus_active);
    },
    canEditHealthPlan() {
      if (this.checkedItems.length === 0)
        return false;

      return this.checkedItems.some(item => item.invoicing_tiss_active)
    },
    isButtonDisabledAccountsReceivable() {
      return !this.checkedItems.length ||
        this.checkedItems.some(item => item.invoicing_tiss_active) ||
        this.checkedItems.some(item => item.invoicing_sus_active);
    }
  },
  data() {
    return {
      healthPlan: null,
    }
  },
  methods: {
    ...mapActions('manageGuides', ['getTissGuideRequiredFields']),
    ...mapActions(
      'pendingItems', ['getPendingAppointmentItems', 'changeCheckedItems', 'changePage']
    ),
    createBill() {
      this.$bvModal.show('bill-items-modal')
    },
    showModalCreateGuide() {
      this.healthPlan = {
        ...this.checkedItems[0]?.health_plan,
        label: this.checkedItems[0]?.health_plan?.fantasy_name,
      }

      if (this.checkedItems.length === 0) {
        this.$bvModal.show('new-tiss-guide-modal');
      } else {
        if (this.checkedItems.some(item => item.invoicing_tiss_active)) {
          this.$bvModal.show('new-tiss-guide-modal');
        } else {
          this.$bvModal.show('new-sus-guide-modal');
        }
      }
    },
    discardPendingItem(itemId) {
      const index = this.pendingItems.findIndex(el => el.id === itemId)
      const copy = Object.assign({}, this.pendingItems[index])
      this.pendingItems.splice(index, 1)
      this.api.updateAppointmentItem(itemId, { solved: true })
        .catch(err => {
          this.$toast.error(err.message)
          this.pendingItems.splice(index, 0, copy)
        })
    },
    onChangePage(page) {
      this.changePage(page)
      this.getPendingAppointmentItems(this.patient.id)
    },
    setClinicHealthPlan(clinicHealthPlan) {
      this.$emit('setClinicHealthPlanToContent', clinicHealthPlan)
    },
    closeSusGuideModal(){
      this.$bvModal.hide('new-sus-guide-modal')
    }
  },
  watch: {
    pendingItems: function(val) {
      this.$emit('pendingItensLength', this.count)
    }
  }
}
</script>
<style lang="scss" scoped>
.account-pending-wrapper {
  padding: 1.5rem 1rem;
  border-radius: 8px;
  border: 1px solid var(--neutral-300);
  margin: 30px 0;
  .account-pending-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .account-pending-title {
      font-size: 16px;
      font-weight: 700;
      color: ar(--dark-blue);
    }
    .card-actions {
      display: flex;
      align-items: center;
      gap: 15px;
    }
  }
  .pagination-position {
    padding: 0;
  }
}
</style>
